//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scrollTop from '@/lib/common-service/scrollTop'
import list from '@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_manage_returnMoney_list'
import SetrRemark from '@/lib/data-service/flight/fligthInternationalAdmin/flight_international_buyer_manage_returnMoney_remark'


export default {
  data() {
    return {
      searchForm: {
        currentPage:1,
        pageSize:10
      },
      totalCount:0,
      flightAirlines: [],
      changeRecordList:[
        {text:'全部',value:1},
        {text:'处理中',value:2},
        {text:'已退款',value:3}
      ],
      NavItemActive:'',
      pageData:[],
      loading:false,
    };
  },
  created(){
    this.search()
  },
  methods: {
    statuSearch(item,index){
       this.NavItemActive = index
       this.searchForm.status = item.value
       this.search()
    },
    searchClear() {

    },
     // 日期选择
    selectPickerOptions() {
      return {
        disabledDate: time => {
            return time.getTime() > Date.now()
        }
      }
    },
    remark(order){
      this.$prompt('请填写备注信息', '备注', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
             SetrRemark({remark:value,refMoneyNo:order}).then(res=>{
               res.status?
               this.$message({type:"success",message:"操作成功！"}):''
             })
        }).catch(() => {
        })
    },
    reset() {
      this.searchForm = {currentPage:1,pageSize:10}
    },
    search(pageNum) {
      let data = this.searchForm
      if(typeof pageNum == 'number'){
          data.currentPage = pageNum||1
      }else{
        data.currentPage = 1
      }
      data.refundTimeStat?data.refundTimeEnd = this.$moment().format('YYYY-MM-DD'):data.refundTimeEnd = ''
      list(data).then(res=>{
        this.pageData = res.pageResult.pageData
        this.totalCount = res.pageResult.totalCount
        scrollTop(500)
      })
    },
    handlerSize(val){
      this.searchForm.pageSize = val
      this.search()
    },
    handlerPage(val){
      // this.searchForm.currentPage = val
      this.search(val)
    }
  }
};
