const __request = require(`../__request/__request_contentType_form`);

// 国际机票退款列表
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/buyer/manage/returnMoney/list',
    data:data
  }

  return __request(pParameter)
}

