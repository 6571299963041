const __request = require(`../__request/__request_contentType_json`);

// 国际机票退款备注
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/flight/international/buyer/manage/returnMoney/remark',
    data:data
  }

  return __request(pParameter)
}

